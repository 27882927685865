import tw from "tailwind-styled-components";
import styled from "styled-components";

export const CardGridWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: var(--spacingS);
  width: 100%;
  margin: 0px;
  padding: 0px;

  @media screen and (min-width: 1128px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--spacingM);
  }
`;

export const BigCardWrapper = styled.li`
  color: var(--primary800);

  a {
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;
  }

  @media screen and (min-width: 1128px) {
    grid-column: span 2 / span 2;

    a {
      text-decoration-thickness: 2px;
    }
  }
`;

export const BigCard = tw.article`
  bg-secondary-100
  rounded-2xl
  text-4xl
  text-center
  font-extralight
  px-6
  py-16

  desktop:flex
  desktop:items-center
  desktop:text-6xl
  desktop:p-20
  desktop:h-full
`;


export const AnchorWrapper = styled.div`
  padding-bottom: 40px;
  color: var(--cta800);
  display:flex;
  flex-direction:column;  
  flex-wrap:wrap;
  gap:14px;

  a {
    display:flex;
    gap: 8px;
  }

  @media screen and (min-width: 1128px) {
    flex-direction:row;
    justify-content:center;
    gap:24px;
    a:not(:last-of-type) {
      border-right: 1px solid #CCC;
      padding-right: 24px;
    }
  }
  desktop:[&_a]:[&:not(:last-child)]:border-r-2
  desktop:[&_a]:last-child:border-r-0
  
`;
